import React from "react";
import { Box } from "@mui/material";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { getItem } from "../../../shared/getEndpoint";
import { trackEvent } from "../../../shared/helpers";
//import { ApplePayButton } from "./ApplePayButton";

function CheckoutButtons({ item, setItem, currentUser }) {
  const createOrderHandler = (item) => {
    // @ts-ignore
    return async (data, actions) => {
      const { paymentSource } = data;
      trackEvent({ action: "begin_checkout" });
      const items = [{ id: String(item._id), qty: "1" }];
      return currentUser.callFunction("createPaypalOrderAxios", { sshost: window.location.host, items, paymentSource })
        .then(({ id }) => id)
        .catch((e) => console.log("createOrderHandler ERR", e));
    };
  };

  const onApproveHandler = (item) => {
    return async (data) => { // CHECKOUT.ORDER.APPROVED
      // await currentUser.callFunction("getProfile", { test: 1 }); // to ensure valid token
      const docs = await getItem(item._id);
      const status = docs[0].status;
      if (status !== "active") {
        setItem({ ...docs[0] });
        return false;
      }

      item.status = "sold";
      if (item.inventoryCount) {
        item.inventoryCount = item.inventoryCount - 1;
      }

      let details = new Promise(() => null);
      try {
        const userProviderType = currentUser.providerType;
        details = await currentUser.callFunction("capturePaypalOrderPaymentv2", 
          {
            sshost: window.location.host,
            orderId: data.orderID,
            data,
            items:[item],
            userProviderType
          }
        );

        console.log("order.capture", { item }, { details }, { data });
        // update UI
        setItem({ ...item });
        trackEvent({
          action: "purchase",
          params: { value: item.price, currency: "USD", name: item.title },
        });
      } catch (ex) {
        console.error(ex);
      }
      return details;
    };
  };

  return (
    <Box sx={{ px: 2 }}>
      <>
        {/* <ApplePayButton currentUser={currentUser} item={item} setItem={setItem} /> */}
        <PayPalButtons
          disabled={item.status === "sold"}
          createOrder={createOrderHandler(item)}
          onApprove={onApproveHandler(item)}
          onError={(err) => {
            trackEvent({ action: "buy_error" });
            console.log("PayPalButtons", err.message);
            alert("PayPal Checkout error: " + err.message);
          }}
        />
      </>
    </Box>
  );
}

export default CheckoutButtons;
